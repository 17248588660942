import { IRecommendedPlan } from '../types';

export const validateRecommendedPlanFields = (plans: IRecommendedPlan[]) => {
  const validationResult: {
    errors: Record<string, string | number>[];
    info: Record<string, string | number>[];
  } = {
    errors: [],
    info: [],
  };

  const validPlansList: IRecommendedPlan[] = [];
  plans.forEach((plan) => {
    const { ctn, OFFER_SOC, OFFER_DEVICE_PARENTCEC, OFFER_DEVICE_COLLECTION } =
      plan;

    if (!ctn) {
      validationResult.errors.push({
        ...plan,
        OFFER_SOC,
        OFFER_DEVICE_PARENTCEC,
        message: `Field "CUSTOMER_IDENTIFIER" is missing.`,
      });
    } else if (!OFFER_SOC) {
      validationResult.errors.push({
        ...plan,
        ctn,
        OFFER_DEVICE_PARENTCEC,
        message: `Field "OFFER_SOC" is missing for CUSTOMER_IDENTIFIER: ${ctn}`,
      });
    } else if (!OFFER_DEVICE_PARENTCEC && OFFER_DEVICE_COLLECTION) {
      validationResult.errors.push({
        ...plan,
        ctn,
        OFFER_SOC,
        message: `Field "OFFER_DEVICE_PARENTCEC" is missing for CUSTOMER_IDENTIFIER: ${ctn} and OFFER_DEVICE_COLLECTION: ${OFFER_DEVICE_COLLECTION}`,
      });
    } else if (OFFER_DEVICE_PARENTCEC && !OFFER_DEVICE_COLLECTION) {
      validationResult.errors.push({
        ...plan,
        ctn,
        OFFER_SOC,
        message: `Field "OFFER_DEVICE_COLLECTION" is missing for CUSTOMER_IDENTIFIER: ${ctn} and OFFER_DEVICE_PARENTCEC: ${OFFER_DEVICE_PARENTCEC}`,
      });
    } else {
      validPlansList.push(plan);
    }
  });
  return { validationResult, validPlansList };
};
