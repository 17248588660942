import { getUnixTime } from 'date-fns';
import { uniqBy } from 'lodash';

export function segregateOffersFromSameRow(
  offersJsonData: Record<string, any>[]
): Record<string, any>[] {
  if (offersJsonData?.length < 1) return [];

  let transformedData: any[] = [];
  const prefixText = 'OFFER_';

  offersJsonData.forEach((offer) => {
    const ctn = `${offer.CUSTOMER_IDENTIFIER || ''}`.trim();
    const offerData = {} as any;

    for (const [key, value] of Object.entries(offer)) {
      if (key.startsWith(prefixText)) {
        const parts = key.split('_'); // Split the key by underscore (eg: ["OFFER", "1", "DEVICE", "PARENTCEC"])
        const number = parts[1]; // eg: "1"
        const field = parts.slice(2).join('_').trim(); // Extract the field part (eg: "DEVICE_PARENTCEC")
        const newKey = `${prefixText}${field}`;
        offerData[number] = offerData[number] || {};
        if (newKey === 'OFFER_DEVICE_PRICE') {
          offerData[number][newKey] = `${value || 0}`.trim();
        } else {
          offerData[number][newKey] = `${value || ''}`.trim() || undefined;
        }
      }
    }

    for (const number in offerData) {
      const currentDate = new Date();
      const createdAt = getUnixTime(currentDate);
      transformedData.push({
        id: `Ctn${ctn}Soc${offerData[number]['OFFER_SOC']}Cec${
          offerData[number]['OFFER_DEVICE_PARENTCEC'] || ''
        }`,
        ctn: ctn,
        offer_no: number,
        createdAt,
        ...offerData[number],
      });
    }
  });

  transformedData = transformedData
    .map((offerEntry) => {
      const {
        OFFER_DATE,
        OFFER_EXPIRY_DAY,
        OFFER_OCN_TYPE,
        OFFER_SOC,
        OFFER_REC_TYPE,
        OFFER_TYPE,
        OFFER_CONTRACTLENGTHTEXT,
        OFFER_DATA_INCLUDED_GB,
        OFFER_MRC,
        OFFER_PLAN_TYPE,
        OFFER_DEVICE_PARENTCEC,
      } = offerEntry;
      const isEmptyOfferEntry =
        !OFFER_DATE &&
        !OFFER_EXPIRY_DAY &&
        !OFFER_OCN_TYPE &&
        !OFFER_SOC &&
        !OFFER_REC_TYPE &&
        !OFFER_TYPE &&
        !OFFER_CONTRACTLENGTHTEXT &&
        !OFFER_DATA_INCLUDED_GB &&
        !OFFER_MRC &&
        !OFFER_PLAN_TYPE &&
        !OFFER_DEVICE_PARENTCEC;
      // eslint-disable-next-line array-callback-return
      if (isEmptyOfferEntry) return;
      return offerEntry;
    })
    .filter(Boolean);

  const uniqueTransformedData = uniqBy(transformedData, 'id');
  return uniqueTransformedData;
}
